<template>
    <div id="home_page_slider">
        <div class="environment-Slider">
            <div class="vertical-slider">
                <div id="vertical-slider-container" class="vertical-slider__container">
                    <div
                        id="js-vertical-slider-wrapper"
                        class="js-vertical-slider-wrapper">
                        <div
                            class="js-intro-teaser video-container"
                            v-for="(item) in slides"
                            :key="item">
                            <div class="js-intro-teaser-content">
                                <div class="header-title luxury">{{ item.title }}</div>
                                <img src="../../../assets/imgs/project-inside.png" class="slide-image" alt="">
                                <div class="about">
                                    <h2 class="title fira">{{ item.city }}</h2>
                                    <span class="description">{{ item.description }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="proxy" class="proxy"></div>
            </div>
        </div>
    </div>
</template>

<script>
import gsap from 'gsap'
import {Draggable} from "gsap/dist/Draggable";

gsap.registerPlugin(Draggable);

export default {
    data() {
        return {
            activeSlide: 0,
            swiper: null,
            slides: [
                {
                    title: 'Environment',
                    city: 'Batumi',
                    description: 'Lorem Ipsum is simply dummy ' +
                        'text of the printing and typesetting industry.' +
                        ' Lorem Ipsum has been the industry\'s standard dummy text ever since'
                },
                {
                    title: 'Environment',
                    city: 'Batumi',
                    description: 'Lorem Ipsum is simply dummy ' +
                        'text of the printing and typesetting industry.' +
                        ' Lorem Ipsum has been the industry\'s standard dummy text ever since'
                },
                {
                    title: 'Environment',
                    city: 'Batumi',
                    description: 'Lorem Ipsum is simply dummy ' +
                        'text of the printing and typesetting industry.' +
                        ' Lorem Ipsum has been the industry\'s standard dummy text ever since'
                },
                {
                    title: 'Environment',
                    city: 'Batumi',
                    description: 'Lorem Ipsum is simply dummy ' +
                        'text of the printing and typesetting industry.' +
                        ' Lorem Ipsum has been the industry\'s standard dummy text ever since'
                },
                {
                    title: 'Environment',
                    city: 'Batumi',
                    description: 'Lorem Ipsum is simply dummy ' +
                        'text of the printing and typesetting industry.' +
                        ' Lorem Ipsum has been the industry\'s standard dummy text ever since'
                },
            ]
        };
    },
    methods: {
        initSwiper() {
            let vm = this;
            let container = document.querySelector("#vertical-slider-container");
            // let wrapper = document.querySelector('#js-vertical-slider-wrapper')
            let proxy = document.querySelector("#proxy");
            let boxes = document.querySelectorAll(".js-intro-teaser");
            let boxCount = boxes.length;
            let containerWidth = Math.ceil(container.getBoundingClientRect().width) - 700;
            let totalWidth = containerWidth * boxCount;
            let slider = {value: 0, follow: 0, ease: 0.08};
            let proxyWidth = window.innerWidth / 2;
            let proxySegment = containerWidth/4;
            let bounds = {minX: 100, maxX: -proxyWidth};
            let ratio = proxyWidth / (totalWidth - containerWidth);
            // let ease = this.CustomEase.create(
            //     "custom",
            //     "M0,0,C0.146,0.398,0.2,1,1,1"
            // );
            let ease = 'Power1.easeOut';
            let duration = 0.6;
            let animated = false;
            gsap.set("#js-vertical-slider-wrapper", {width: totalWidth});
            gsap.set(".js-intro-teaser", {width: containerWidth});

            let draggable = new Draggable(proxy, {
                type: "x",
                trigger: container,
                bounds: bounds,
                throwProps: !0,
                edgeResistance: 0.9,
                snap: snapX,
                onDrag: updateSlider,
                onThrowUpdate: updateSlider,
                onPress: onPress,
                onRelease: throwComplete,
                onThrowComplete: throwComplete
            });

            function onPress(ev) {
                if (animated) return;

                slideDown(ev);
            }

            function slideDown(e) {
                let target = e.target || e.eventTarget;
                if (animated || target.id === "explore-main") return;

                // gsap.to(".js-intro-teaser-content", {
                //     scale: 0.7,
                //     duration,
                //     ease
                // });
                gsap.to(".js-intro-teaser-content .header-title,.about", {
                    opacity: 0,
                    duration: duration + 0.2,
                    ease
                });
            }

            function slideUp() {
                if (animated) return;

                gsap.to(".js-intro-teaser-content", {
                    scale: 1,
                    ease,
                    duration
                });
                gsap.to(".js-intro-teaser-content .header-title,.about", {
                    opacity: 1,
                    duration: duration + 0.2,
                    delay: .3,
                    ease
                });
            }

            function snapX(value) {
                if (animated) return;

                let snap = Math.round(value / proxySegment);
                vm.activeSlide = Math.max(0, Math.min(boxCount - 1, -snap));
                return snap * proxySegment;
            }

            function updateSlider() {
                let that = gsap.getProperty(".proxy", "x");
                Math.round(that / proxySegment) * -1;
                slider.value = draggable.x;
            }

            function throwComplete() {
                if (animated) return;

                if (!draggable.isThrowing) {
                    let value = snapX(gsap.getProperty(".proxy", "x"));
                    gsap.to("#js-vertical-slider-wrapper", {
                        x: value
                    });
                    gsap.set(".proxy", {
                        x: value
                    });
                    slider.value = value;
                    slideUp();
                }
            }

            function tick() {
                if (animated) return;
                slider.follow += (slider.value - slider.follow) * slider.ease;
                gsap.set("#js-vertical-slider-wrapper", {
                    x: slider.follow / ratio
                });
            }

            function removeTicker() {
                gsap.ticker.remove(tick);
            }

            gsap.ticker.add(tick);

            window.addEventListener("resize", function () {
                containerWidth = Math.ceil(container.getBoundingClientRect().width);
                totalWidth = containerWidth * boxCount;
                proxyWidth = window.innerWidth / 2;
                proxySegment = proxyWidth / (boxCount - 1);
                bounds = {minX: 0, maxX: -proxyWidth};
                ratio = proxyWidth / (totalWidth - containerWidth);
                draggable.applyBounds(bounds);
                gsap.set(proxy, {x: -vm.activeSlide * proxySegment});
                draggable.update();
                slider.value = draggable.x;
                gsap.set("#js-vertical-slider-wrapper", {
                    width: totalWidth
                });
                gsap.set(".js-intro-teaser", {width: containerWidth});
            });

            return {
                remove: removeTicker
            };
        },
    },
    mounted() {
        this.swiper = this.initSwiper();
    },
    beforeDestroy() {
        if (this.swiper) this.swiper.remove();
        // document.querySelector(".default").classList.remove("bg-gray");
    }
}
</script>

<style lang="scss" scoped>
#home_page_slider {
    margin-top: 200px;

    .vertical-slider {
        position: relative;
        width: 100%;
        height: 100%;
        cursor: grab;
        @media (max-width: 1023px) {
            max-width: 90%;
            overflow: hidden;
            margin: auto;
        }
    }

    .vertical-slider__container {
        position: relative;
        width: 100%;
        height: 100%;
        margin-right: auto;
        margin-left: auto;
    }

    .js-vertical-slider-wrapper {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        margin-left: 0;
        padding-right: 0;
        padding-left: 0;
        align-items: center;
        cursor: grab;
        //margin: 0 200px;

        .js-intro-teaser {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            flex-shrink: 0;
            background-color: transparent;
            //margin-right: 100px;
            &:last-child {
                margin-right: 0;
            }

            @media (max-width: 1365px) {
                max-width: calc(100vw - 94px);
            }
            @media (max-width: 1023px) {
                max-width: 90vw;
            }

            .js-intro-teaser-content {
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                position: relative;

                .slide-image {
                    max-height: 610px;
                    width: 100%;
                    object-fit: cover;
                }

                .header-title {
                    position: absolute;
                    left: 50%;
                    top: 0;
                    transform: translate(-50%, -50%);
                    font-style: normal;
                    font-weight: 400;
                    font-size: 89px;
                    color: $primaryNavy;
                    text-transform: uppercase;
                }

                .about {
                    margin-top: 34px;
                    color: $primaryNavy;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;

                    .title {
                        font-weight: 700;
                        font-size: 34px;
                        text-align: center;
                        letter-spacing: 0.1em;
                        text-transform: uppercase;
                    }

                    .description {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 21px;
                        line-height: 25px;
                        text-align: center;
                        max-width: 60%;
                        display: block;
                        margin-top: 13px;
                    }
                }
            }
        }

    }


    .proxy {
        position: relative;
        left: 50%;
        display: none;
        width: 100px;
        height: 100px;
    }

}

</style>
